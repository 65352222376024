import rules from "@/utils/validation";
import SpeechToText from "@/components/plugins/SpeechToText";
import TextToSpeech from "@/components/plugins/TextToSpeech.vue";
import Translator from "@/components/plugins/Translator.vue";

export const askQuestionMixin = {
	data() {
		return {
			rules,
			interval_id: null,
		};
	},

	components: {
		SpeechToText,
		TextToSpeech,
		Translator,
	},
};

export const toolsQueryMixin = {
	data() {
		return {
			answer_styles: [
				"subtitle-1 font-weight-thin",
				"mt-4",
				"white-space-pre-line-wr",
			],
		};
	},

	created() {
		this.readyToListenEvents();
	},

	methods: {
		async createQuery(query) {
			return await this.$store.dispatch("query/create", {
				type: "completion",
				query,
			});
		},

		async getQueryResponse(query_id) {
			return await this.$store.dispatch("query/get", {
				query_id,
			});
		},

		async prepareQuery(input_query) {
			// Create a query first
			let res = await this.createQuery(input_query);
			/**
			 * Why use raw promise?
			 * Because we need setinterval to finish its job and then return
			 * the control from here to the parent function.
			 */
			return new Promise((resolve, reject) => {
				// This event is to start countdown in the loader component
				// because for GPT requests we need to show countdown instead
				// of package loader component.
				this.$eventBus.$emit("start-timer");
				// Get the created query's result in every 5 second until we get response
				this.interval_id = setInterval(async () => {
					let response = await this.getQueryResponse(res.id);
					// If response if not empty then,
					if (response.result) {
						// Stop the countdown
						this.$eventBus.$emit("stop-timer");
						// clear the interval
						clearInterval(this.interval_id);
						// resolve the response so it can return it to callee
						resolve(response);
					}
				}, 5000);
			});
		},
	},
};

export const mindmapJSONMixin = {
	methods: {
		onOperationPerformed(payload) {
			if (payload.name == "finishEdit") {
				this.temp.push(payload.obj.topic);
			}
		},

		currentRound(step) {
			return this.attempts.filter((item) => item.step == step).length + 1;
		},

		isMapModified(map1, map2) {
			// check if new map has modified
			if (JSON.stringify(map1) !== JSON.stringify(map2)) {
				return true;
			}
			throw new Error(
				"Please add at least 2 new branches and add 2 new sub-categories to any branch you choose.",
				{
					cause: "werCustom",
				},
			);
		},

		reverseJSON(map) {
			let obj = {};
			obj.name = structuredClone(map.data.nodeData.topic);
			obj.children = structuredClone(map.data.nodeData.children);

			preparechild(obj.children);

			function preparechild(arr) {
				if (arr) {
					arr.map((child) => {
						child.name = child.topic;
						Object.keys(child).forEach((key) => {
							if (!["name", "children"].includes(key)) {
								delete child[key];
							}
						});
						if (child.children) {
							preparechild(child.children);
						}
					});
				}
			}

			return obj;
		},
	},
};
